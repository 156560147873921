import { useState, useEffect, FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { getAllOfferPayedFinance } from '../../global-state/actions/finance-actions';
import { useHistory } from 'react-router';
import { redirect, clearHistoryArray } from '../../global-state/actions/breadcrumb-actions';
import TableElement from '../../elements/table/normal-table-element';
import TableLoading from './table-loading'
import { getTrainingsFinance } from '../../global-state/actions/training-actions';
import EmptyListElement from '../../elements/empty-list/normal-empty-list-element';
import { returnStartPathUrl } from '../../helpers/domainCheck';
import { fetchData } from '../../base/functions/Functions';
import { getSizePayedFinanceApi } from '../../services/finance.service';
interface ObjectCoursesPayed {
    t: any
    filter: any
    setTotalTraningGains: any
}


interface FinanceTrainingInterface {
    averagePrice: number
    lastPrice: string
    numberInscribedStudents: number
    publishDate: string
    teacherName: string
    totalIncome: number
    trainingId: number
    trainingName: string
    trainingOfferId: number

}



const OffersListComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ObjectCoursesPayed> = (props: any) => {

    const t = props?.t
    let [filter, setfilter] = useState(props?.filter)
    const history = useHistory()
    let [size, setSize] = useState<any>()

    const [atBottom, setAtBottom] = useState<any>(false)
    const [loading, setLoading] = useState(false);
    const headers = [
        { title: t('TitleFormations'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
        { title: t('Professeurs'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('PublishedAt'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('Registered'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
        { title: t('ActualPrice'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
        { title: t('MoyPrix'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto" },
        { title: t('resultat'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
    ]

    const [financeTraining, setfinanceTraining] = useState<FinanceTrainingInterface[]>([])
    const [financeTrainingPageNumber, setfinanceTrainingPageNumber] = useState<number>(0)
    const [financeTrainingLoadingPage, setfinanceTrainingLoadingPage] = useState<boolean>(true)
    const [trainingFinanceFilter, setTrainingFinanceFilter] = useState({
        "schoolId": props?.user?.idSchool,
        "page": financeTrainingPageNumber,
        "search": ""
    })

    useEffect(() => {
        getInitialData()
        return () => {
            setfinanceTrainingPageNumber(0)
            setfinanceTrainingLoadingPage(true)
            setTrainingFinanceFilter({
                "schoolId": props?.user?.idSchool,
                "page": 0,
                "search": ""
            })
            setfinanceTraining([])
        }
    }, [])

    const getInitialData = async () => {
        let filter = {
            "schoolId": props?.user?.idSchool,
            "page": financeTrainingPageNumber,
            "search": ""
        }

        setfinanceTrainingLoadingPage(true)
        setTrainingFinanceFilter(filter)
        fetchFininaceTrainingList(filter)
        setfinanceTrainingLoadingPage(false)
    }


    const fetchFininaceTrainingList = async (trainingFinanceFilter: any) => {
        let data: FinanceTrainingInterface[] = []
        data = await fetchData('POST', `${process.env.REACT_APP_BASE_URL14}finance/getFinanceTrainingsWithFilter`, trainingFinanceFilter)
        if (data.length !== 0) {
            setfinanceTraining([...financeTraining, ...data])
            setfinanceTrainingPageNumber(financeTrainingPageNumber + 1)
        }

        setLoading(false)
    }

    useEffect(() => {
        getSizePayedFinance(filter, props?.school.id);
        filterOffersPayed(filter)
    }, [])

    useEffect(() => {
        setfilter(props?.filter)
        getSizePayedFinance(props?.filter, props?.school.id);
        filterOffersPayed(props?.filter)
    }, [props?.filter])

    const getSizePayedFinance = async (filter: any, id: any) => {
        const result = await getSizePayedFinanceApi(filter, id)
        setSize(result)
    }

    const handleScroll = async () => {
        let filterUpdated = trainingFinanceFilter;
        filterUpdated['page'] = financeTrainingPageNumber;
        if (!(atBottom) && (window.innerHeight + window.scrollY) >= document.body.offsetHeight && financeTraining.length < size) {
            setAtBottom(true);
            setLoading(true)
            fetchFininaceTrainingList(filterUpdated)
            setTrainingFinanceFilter(filterUpdated)
            document.removeEventListener('scroll', handleScroll);
        }
        if ((window.innerHeight + window.scrollY) <= document.body.offsetHeight) {
            setAtBottom(false);
        }
    }


    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })

    const filterOffersPayed = (e: any) => {
        props?.getAllOfferPayedFinance(e, props?.school.id, "first");
    }

    const data: any = useMemo(() => {
        return {
            headers,
            body: financeTraining?.map((training: any) => {

                return {
                    row: {

                        classNames: "table-white",
                        columns: [
                            { content: training?.trainingName, classNames: "", actionName: '' },
                            { content: training?.teacherName, actionName: '' },
                            { content: training?.publishDate, classNames: "", actionName: '' },
                            { content: training.numberInscribedStudents, classNames: "" },
                            { content: training.totalIncome === 0 ? t("gratuit") : (training.totalIncome + "€"), classNames: "text-center", actionName: '' },
                            { content: (isNaN(training?.averagePrice) ? 0 : (training.averagePrice % 1 === 0) ? training.averagePrice : training.averagePrice?.toFixed(2)) + "€", classNames: "text-center", actionName: '' },
                            { content: (training.totalIncome + "€"), classNames: "text-end", actionName: '' }
                        ]
                    }
                }

            })
        }
    }, [financeTraining])

    const doAction = (obj: any) => {

        props?.clearHistoryArray()
        props?.redirect("Finance", "/finance/all")
        props?.redirect(financeTraining[obj?.index]?.trainingName, `${returnStartPathUrl()}/finance/detail-course/${financeTraining[obj?.index]?.trainingOfferId}`)
        history.push(`${returnStartPathUrl()}/finance/detail-course/${financeTraining[obj?.index]?.trainingOfferId}`)

    }


    return (
        <>

            <div className=" justify-content-center mt-0 w-100 mx-auto finance-height-table" style={{ maxWidth: '1200px' }}>

                {!financeTrainingLoadingPage && (financeTraining?.length > 0) &&
                    <>
                        <TableElement data={data} doAction={doAction} />

                        {(financeTraining.length > 0 && loading) &&
                            <div className="d-flex w-100 align-items-center justify-content-center">
                                <div className="m-3">
                                    <svg className="spinner" viewBox="0 0 50 50">
                                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                    </svg>
                                </div>
                            </div>
                        }
                        <div className="table-responsive  d-flex mx-auto" style={{ position: 'sticky', bottom: '0px' }} >
                            <table className="w-100 custom-table">
                                <thead>
                                    <tr>
                                        <th className='pb-0 display-header w-20' >{t('TitleFormations')}</th>
                                        <th className='pb-0 display-header ' >{t('Professeurs')}</th>
                                        <th className='pb-0 display-header ' >{t('PublishedAt')}</th>
                                        <th className='pb-0 display-header ' >{t('Registered')}</th>
                                        <th className='pb-0 display-header ' >{t('ActualPrice')}</th>
                                        <th className='pb-0 display-header ' >{t('MoyPrix')}</th>
                                        <th className='pb-0 display-header ' >{t('resultat')}</th>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr className="table-black">
                                        <td className="table-black text" colSpan={3}>{t('Total')}</td>
                                        <td className="table-black text text-center"><div className="d-flex flex-column"><span>{financeTraining.length > 0 && financeTraining.reduce((acc, item) => acc + (item.numberInscribedStudents), 0)}</span><span className="table-black-title">{t('TotalInscrits')}</span></div></td>
                                        <td className="table-black text text-center"><div className="d-flex flex-column"><span>{financeTraining.length > 0 && (Number(financeTraining.reduce((acc, item) => acc + (Number(item.totalIncome)), 0)) / financeTraining.length).toFixed(2)} {props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span><span className="table-black-title">{t('periodMedian')}</span></div></td>
                                        <td className="table-black text text-center"><div className="d-flex flex-column"><span>{financeTraining.length > 0 && financeTraining.reduce((acc, item) => acc + (!isNaN(item.averagePrice) ? (Number(item.averagePrice)) : 0), 0).toFixed(2)} {props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span><span className="table-black-title">{t('totalMedian')}</span></div></td>
                                        <td className="table-black text text-center"><div className="d-flex flex-column"><span>{financeTraining.length > 0 && financeTraining.reduce((acc, item) => acc + (Number(item.totalIncome)), 0).toFixed(2)} {props?.paymentMethod?.currency === "TND" ? "TND" : "€"}</span><span className="table-black-title">{t('totalResult')}</span></div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                    </>}
                {!financeTrainingLoadingPage && (financeTraining?.length === 0)
                    && <div className='mt-5 pt-5'>
                        <div className="msg-list-empty">
                            <EmptyListElement lines={[t('noCoursePayedDefault')]} />
                        </div>
                    </div>
                }
                {financeTrainingLoadingPage && <TableLoading />}
            </div>

        </>
    )
}
const mapStateToProps = (state: AppState) => ({
    school: state.schoolInfoReducer.schoolInfo,
    listeHistory: state.historyReducer.listHistoryBreadCrumb,
    paymentMethod: state.schoolInfoReducer.paymentSchoolInfo,
    user: state.authReducer.connectedUser,
    pageNumber: state.courseReducer.pageNumber,

});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getTrainingsFinance, getAllOfferPayedFinance, redirect, clearHistoryArray
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(OffersListComponent);
