import { fetchData } from "../base/functions/Functions";



const webinarBaseUrl= process.env.REACT_APP_BASE_URL9

export const fetchWebinarCount = async (idSchool: number, userId:number) => {
    try {
        return await fetchData('GET', `${webinarBaseUrl}webinars/fetchNumberOfWebinarBySchoolId/${idSchool}/${userId}`, {});
    } catch (error) {
        console.error("Occurred error while counting number of webinar:", error);
        return 0;
    }
}