import { ComponentProps, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { openModal, closeModal } from '../../global-state/actions/modal-actions';
import { ModalIDS } from '../../components/modals/modal-ids';
import { clearFormGroup ,getFiltredGroup,getGroupBySchool} from '../../global-state/actions/group-actions';
import SelectElement from '../../elements/select/select-element';

const FilterStudentGroupsComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const [searchFilter, setSearchFilter] = useState("");
    const [training] = useState("none");
    const [trainingOption, setTrainingOption] = useState<any[]>([]);
    useEffect(() => {
        const array = [{value:"all", label:t('allSubjects')},props?.allCourses?.filter((obj: any) => { return obj.status==="PUBLISHED"}).map((trainingObj:any)=>{
            return { value: JSON.stringify({ idsGroup: trainingObj.groups}), label: trainingObj?.title }
        })].flat(Infinity)
        setTrainingOption(array);
    }, []);

    const changeTraining = (trainig: string) => {
        props?.filter(true)
        if(trainig!=="all"){
            let trainigObj = JSON.parse(trainig);
            props?.getFiltredGroup({ ids:(trainigObj.idsGroup)?trainigObj.idsGroup:[]})
        }else{
            props?.getGroupBySchool(props?.schoolInfo?.id)
        }
    }
    const filterListText = (value: string) => {
        setSearchFilter(value);
        if(value.length >= 3 || value.length === 0 ){
            props?.Searching(value)
        }
  
    }

    const openModalFn = async () => {
        await props?.clearFormGroup();
        await props?.openModal({ idModal: ModalIDS.ADD_NEW_GROUP, size: "md" })
    }

    return (
        <div className='col-md-12 mx-auto'style={{ maxWidth: '1200px' }}>
        <div className="mt-4 p-0 " >
             <div className="d-flex -align-items-center justify-content-between w-100 px-0" >
                 <button type="button" style={{ marginRight: '16px' }} className="btn-Secondary large icon-left" onClick={() => openModalFn()}>
                     <span className="material-icons-outlined" style={{ marginRight: '8px' }} >add</span>
                     <div className="m-auto ps-2">{t('newGroup')}</div>
                 </button>
                 <div className=" d-flex">
                     <div className="me-3" style={{width: '220px' }}>
                 <SelectElement disabled={false} taille={'lg'} defaultValue={ training} options={trainingOption } placeholder={t('allSubjects')} onChange={(e: string) => changeTraining(e)} />
                 </div>
                 <div className="input-form-control" style={{ maxWidth: '220px' }}>
                     <input dir="ltr"type="text" className="form-control input text-default" placeholder={t('searchProfessors')} value={searchFilter} onChange={(e: any) => { filterListText(e.target.value); }} />
                     <span className="material-icons-outlined">search</span>
                 </div>
                 </div>
             </div>
         </div>
         </div>
    );
}

const mapStateToProps = (state: AppState, ownProps: any) => ({
    nameSchool: state.authReducer.connectedSchoolInfo?.code,
    connectedUser: state.authReducer.connectedUser,
    lastTabClicked: state.profReducer.lastTabClicked,
    Searching: ownProps?.Searching,
    filter:ownProps?.filter,
    allCourses: state.courseReducer.allCourses,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
 
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            openModal,
            closeModal,
            clearFormGroup,
            getFiltredGroup,
            getGroupBySchool

        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(FilterStudentGroupsComponent);
