import { ComponentProps, FC, useEffect, useState } from "react"
import { connect } from "react-redux";
import { AppState } from "../../global-state/store/root-reducers";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { getMyCourses, changeStyleCardCourse } from '../../global-state/actions/course-actions';
import EmptyListElement from "../../elements/empty-list/normal-empty-list-element";
import CardWebinarVertical from "./card-webinar/card-webinar-vertical-component";
import CardWebinarHorizontal from "./card-webinar/card-webinar-horizontal-component";
import CardWebinarVerticalLoading from './card-webinar/card-webinar-vertical-loading';
import CardWebinarHorizontalLoading from './card-webinar/card-webinar-horizontal-loading';
import { getCatalog } from "../../global-state/actions/catalog-duplicaction-actions";
import Webinar from "./webinar-model/Webinar";
import SelectElement from '../../elements/select/select-element';
import { useHistory } from "react-router";
import { returnStartPathUrl } from "../../helpers/domainCheck";
import { fetchData } from "../../base/functions/Functions";
import { fetchListTrainingForSelectInput } from "../../services/training.service";
interface Filters {
    subjectType: string,
    statusWebinar: string,
    subjectIds: any[],
    sort: string,
    search: string
}
const StudentListCardComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const listTrie = [
        { label: t('SortsMostRecent'), value: "desc", id: 1 },
        { label: t('SortOldest'), value: "asc", id: 2 },
    ]
    const listStatus = [
        { label: t('allStatus'), value: '', id: 1 },
        { label: t('SOON'), value: 'SOON', id: 2 },
        { label: t('ONGOING'), value: 'ONGOING', id: 3 },
        { label: t('ENDED'), value: 'ENDED', id: 4 },
    ]
    const history = useHistory();
    const [webinars, setWebinars] = useState<any>([])
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState<Filters>({
        subjectType: "",
        statusWebinar: "",
        subjectIds: [],
        sort: "asc",
        search: ""
    })
    const [coursesOption, setCoursesOption] = useState<any[]>([]);
    const [defaultValueTrie] = useState("asc");
    const [defaultValueStatus] = useState("none");
    const [defaultValueCourse] = useState("all");

    const fetchDetails = async () => {

        const list = await fetchListTrainingForSelectInput({
            entityType: 'SCHOOL',
            entityId: props?.connectedUser?.idSchool,
            role: "STUDENT"
        })
        setCoursesOption(list)

    }

    const handleChoice = (e: any) => {
        if (e === "all") {
            setFilters({ ...filters, subjectIds: coursesOption?.map((c: any) => { return c?.id }) })
        } else {
            setFilters({ ...filters, subjectIds: [e] })
        }

    }


    const getWebinars = async (filters: any) => {        
        setLoading(true)
        const baseUrl = process.env.REACT_APP_BASE_URL9;
        let listWebinars = await fetchData("POST", baseUrl + `webinars/filter`, filters).then(res => { return res }).catch(err => { return [] });
        let webinarsRefused = listWebinars?.filter((obj: any) => obj?.webinarStatusHistories.sort((a: any, b: any) => (a?.creationDate < b?.creationDate) ? 1 : -1)[0]?.status !== "WAITING")?.filter((obj: any) => obj?.webinarStatusHistories.sort((a: any, b: any) => (a?.creationDate < b?.creationDate) ? 1 : -1)[0]?.status !== "REFUSED")
        setWebinars(webinarsRefused)
        setLoading(false)
        return null;
    }


    useEffect(() => {
        fetchDetails()
    }, [])

    useEffect(() => {
        getWebinars(filters)
    }, [filters])




    return (
        <>
            <div className="container-fluid m-0 p-0 mx-auto" >
                <div className="row justify-content-center">
                    {webinars?.length > 0 &&
                        <div className="col-md-12" style={{ backgroundColor: '#ffffff' }}>
                            <div className="row mx-auto" style={{ maxWidth: '1200px' }} >
                                <div className="col-md-12 mt-5" ></div>
                                <div className="py-4 w-100">
                                    <div className="d-flex flex-row justify-content-between my-2">
                                        <div className="d-flex flex-row">
                                            <span className="H2-Headline neutral-3 me-3">{t('Webinars')}</span>
                                            <p className="H2-Headline black-800">{(webinars?.length < 10) ? '0' + webinars?.length : webinars?.length}</p>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex align-items-center me-4">
                                                <span className="material-icons-outlined " onClick={() => props?.changeStyleCardCourse('horizontal')} style={{ cursor: 'pointer', color: `${(props?.styleCardCourse === 'horizontal') ? "#272727" : "#CACBCE"}`, fontSize: 30 }}> view_headline </span>
                                                <span className="material-icons-outlined " onClick={() => props?.changeStyleCardCourse('vertical')} style={{ cursor: 'pointer', color: `${(props?.styleCardCourse === 'vertical') ? "#272727" : "#CACBCE"}`, fontSize: 30 }}> apps </span>
                                            </div>
                                            {/* <Webinar name="add" filters={filters} /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    <div className="container-fluid" style={{ maxWidth: '1200px' }}>
                        <div className="row justify-content-center">
                            {/* <div >

                                {(webinars.length > 0) && <div className="d-flex flex-row m-auto align-items-end my-4 py-2" style={{ columnGap: 8, justifyContent: "flex-end" }}>
                                    <div className="select-input" style={{ minWidth: '225px' }}>
                                        <SelectElement disabled={false} taille={"lg"} defaultValue={defaultValueTrie} options={listTrie} onChange={(e: any) => { setFilters({ ...filters, sort: e }) }} placeholder={t('statusFilter')} />
                                    </div>
                                    <div className="select-input" style={{ minWidth: '225px' }}>
                                        <SelectElement disabled={false} taille={"lg"} defaultValue={defaultValueStatus} options={listStatus} onChange={(e: any) => { setFilters({ ...filters, statusWebinar: e }) }} placeholder={t('statusFilter')} />
                                    </div>
                                    <div className="select-input" style={{ minWidth: '225px' }}>
                                        <SelectElement disabled={false} taille={"lg"} defaultValue={defaultValueCourse} options={coursesOption} onChange={(e: any) => { handleChoice(e) }} placeholder={t('allCourses')} />
                                    </div>
                                    <div className="input-form-control" style={{ maxWidth: '225px' }}>
                                        <input dir="ltr" type="text" className="form-control input text-default" placeholder={t('searchProfessors')} onChange={(e) => { setFilters({ ...filters, search: e?.target.value }) }} />
                                        <span className="material-icons" style={{ color: '#B1B1B1' }}>search</span>
                                    </div>
                                </div>}


                            </div> */}

                            <div >
                                {!loading && <div className={(props?.styleCardCourse === "vertical" && webinars?.length > 0) ? "grid-cards" : "d-flex w-100 flex-wrap align-items-center"}>
                                    {(webinars?.length === 0) &&
                                        <div className="text-center m-auto mt-4 pt-3" >
                                            <div >
                                                <h2 className="H2-Headline ">{t('Webinar')}</h2>
                                            </div>
                                            <div className="col-md-12 text-center " style={{ paddingTop: "200px" }}>
                                                <EmptyListElement lines={[t('soonWebinarsAvailable'), t('willInformuSoon')]} >

                                                </EmptyListElement>
                                            </div>
                                            <div style={{ paddingTop: "32px" }}>
                                                <button type="button" className="btn-Secondary large" onClick={() => { history.push(`${returnStartPathUrl()}/catalog`) }}>
                                                    {t('discoverCatalog')}
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {

                                        webinars?.map((webinar: any, index: number) => {

                                            return (props?.styleCardCourse === "vertical") ?
                                                <div key={index} className=" justify-content-center" >
                                                    <CardWebinarVertical webinar={webinar} key={index} />
                                                </div> :
                                                <div key={index} className="col-md-12 mb-3">
                                                    <CardWebinarHorizontal key={index} webinar={webinar} />
                                                </div>
                                        })
                                    }
                                </div>}
                                {loading && <div className="d-flex w-100 flex-wrap align-items-center justify-content-between ">
                                    {(props?.styleCardCourse === "vertical") ?
                                        <>
                                            <div className="mb-3 justify-content-center">
                                                <CardWebinarVerticalLoading />
                                            </div>
                                            <div className="mb-3 justify-content-center">
                                                <CardWebinarVerticalLoading />
                                            </div>
                                            <div className="mb-3 justify-content-center">
                                                <CardWebinarVerticalLoading />
                                            </div>
                                        </> :
                                        <>
                                            <div className="mb-3 justify-content-center w-100">
                                                <CardWebinarHorizontalLoading />
                                            </div>
                                            <div className="mb-3 justify-content-center w-100">
                                                <CardWebinarHorizontalLoading />
                                            </div>
                                            <div className="mb-3 justify-content-center w-100">
                                                <CardWebinarHorizontalLoading />
                                            </div>
                                        </>
                                    }
                                </div>}

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>


    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    history: ownProps?.history,
    styleCardCourse: state.courseReducer.styleCardCourse,
    user: state.authReducer.connectedUser,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getMyCourses
            , changeStyleCardCourse
            , getCatalog
        },
        dispatch
    );
export default connect(mapStateToProps, mapDispatchToProps)(StudentListCardComponent);