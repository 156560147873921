import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../global-state/store/root-reducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';

import { useTranslation } from 'react-i18next';
import "../../translations/i18n";
import SelectElement from '../../elements/select/select-element';
import { StudentPageFilter } from '../../data-structures/interfaces/school.interfaces';
import { exportList } from '../../global-state/actions/professor-actions';

const studentsStatusList = [
    { label: "Tous les étudiants", value: "All" },
    { label: "Admis", value: "Admis" },
    { label: "En attente", value: "Enattente" },
    { label: "Suspendus", value: "Suspendu" },
]
const FilterStudentPageListComponent: FC<ReturnType<typeof mapStateToProps> & ComponentProps<any>> = (props: any) => {

    const { t } = useTranslation();
    const [searchInput, setSearchInput] = useState<string>("")
    const [studentStatusToDisplay, setStudentStatusToDisplay] = useState<string>("All")
    let exportButtonLoading = useMemo(() => props?.loadingExport, [props?.loadingExport])
    const [filter, setFilter] = useState<StudentPageFilter>({
        idSchool: props?.connectedUser?.idSchool,
        page: 0,
        search: "",
        status: "All",
        courseIdsList: [],
        idPerson: props?.connectedUser?.idPerson
    })

    useEffect(() => {
        if (filter !== props.fetchFilter) {
            setFilter(props.fetchFilter)
        }
    }, [props.fetchFilter])

    useEffect(() => {
        if ((searchInput !== filter?.search) && searchInput?.length > 3) {
            props.updateFilter("search", searchInput)
        }
    }, [filter?.search, props, searchInput])

    useEffect(() => {
        if (studentStatusToDisplay !== filter.status) {
            props.updateFilter("status", studentStatusToDisplay)
        }
    }, [studentStatusToDisplay])

    const fileTitle = (label: any) => {
        switch (label) {
            case "All":
                return t('ListEtds');
            case "Candidate":
                return t('listcand');
            case "Certified":
                return t('listdipv');
            case "Inscribed":
                return t('listInscribed');
            case "Suspended":
                return t('listSuspended')
            case "Unpaid":
                return t('listOfUnpaidStudents');

            default:
                console.error("given value ", label);
        }
    }

    const exportData = async () => {
        const lang = localStorage.getItem("language")
        let name = fileTitle(filter.status) + " " + props?.nameSchool;
        let filterCourse;
        if (props?.connectedUser?.role === 'role_prof') {
            filterCourse = { entityId: props?.connectedUser?.idSchool, entityType: "SCHOOL", idProf: props?.connectedUser?.idPerson, status: "PUBLISHED" };
        } else {
            filterCourse = { entityId: props?.connectedUser?.idSchool, entityType: "SCHOOL", status: "PUBLISHED" };
        }
        await props?.exportList(filterCourse, filter.status, props?.connectedUser?.idSchool, name, lang)
    }





    return (
        <div className="mt-4 p-0" style={{ maxWidth: '1200px' }}>
            <div className={`d-flex -align-items-center justify-content${(props?.inCourse) ? '-end' : '-between'} w-100 px-0`} >
                {exportButtonLoading === false ?
                    <button type="button" style={{ marginRight: '16px' }} className="btn-Secondary large icon-left" onClick={() => { exportData() }}>
                        <span className="material-icons-outlined" style={{ marginRight: '8px' }} >download</span>
                        <div className="m-auto ps-2">{t('exportListe')}</div>
                    </button>
                    :
                    <button className="btn-Secondary loaddata large" style={{ width: "200px", height: "48px" }}>
                        <div className="threedots-large-Animation">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                }

                <div className="d-flex align-items-center flex-wrap">
                    {(props?.connectedUser?.role === 'role_director' && props?.actualTab === "All" && props?.history !== undefined && window !== undefined && window.location.href !== undefined && window.location.href.includes("students/all")) && <div style={{ maxWidth: '225px', minWidth: '225px', marginRight: '10px' }}>
                        <SelectElement disabled={false} taille={'lg'} defaultValue={studentsStatusList[0].value} options={studentsStatusList} onChange={(e: any) => { setStudentStatusToDisplay(e) }} placeholder={t('filterProfessors')} />
                    </div>}

                    <div className="input-form-control" style={{ maxWidth: '220px' }}>
                        <input dir="ltr" type="text" className="form-control input text-default" placeholder={t('searchProfessors')} value={searchInput} onChange={(e: any) => { setSearchInput(e.target.value) }} />
                        <span className="material-icons-outlined">search</span>
                    </div>
                </div>
            </div>
        </div>
    );
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    nameSchool: state.authReducer.connectedSchoolInfo?.code,
    connectedUser: state.authReducer.connectedUser,
    data: ownProps?.data,
    addDataList: ownProps?.addDataList,
    trainings: state.trainingReducer.trainings,
    loadingExport: state.profReducer.loadingExport,
    history: state.historyReducer.lastPathBeforeBreadCrumb
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            exportList
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(FilterStudentPageListComponent);