import { ComponentProps, FC, useEffect, useState } from "react"
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppState } from "../../global-state/store/root-reducers";
import { sendIpAddressCourse, getOfferCourse, getContent, getRegistrationForm, checkFollowStudentCourse, createOrUpdateOfferCourse, getOfferPricingCourse } from "../../global-state/actions/course-actions";
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import SkillsSectionComponent from "../../components/preview-course-components/skills-section-component";
import ContentPathSectionComponent from "../../components/preview-course-components/content-path-section-component";
import SchoolProfSectionComponent from "../../components/preview-course-components/school-prof-section-component";
import HowToWorkSectionComponent from "../../components/preview-course-components/how-to-work-section-component";
import ListFeedbackSectionComponent from "../../components/preview-course-components/list-feedback-section-component";
import DescPreviewCourse from "../../components/preview-course-components/desc-preview-course";
import axiosInstance from "../../helpers/interceptors";
import { useParams } from "react-router-dom";
import { fetchData } from "../../base/functions/Functions";

interface Params {
    idSession: string
}
const levels = [
    { label: 'anyLevelCourse', value: 'ANYLEVEL' },
    { label: 'firstLevel', value: 'BEGINNER' },
    { label: 'secondLevel', value: 'INTERMEDIATE' },
    { label: 'thirdLevel', value: 'ADVANCED' }
]
const PreviewSessionPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const [show, setShow] = useState<boolean>()
    const [courseInfo, setCourseInfo] = useState<any>();
    const location = useLocation();
    const [price, setPrice] = useState<any>()
    const [ips, setIps] = useState('')
    const { idSession } = useParams<Params>();
    const [id, setId] = useState(props.sessionInfoFromProf.id)
    const [trainingLevel, setTrainingLevel] = useState<any>()
    const [contentWidth, setContentWidth] = useState<any>()
    const [enableOfferCreation, setenableOfferCreation] = useState<boolean>(true)
    const [courseTarificationForm, setCourseTarificationForm] = useState(undefined)
    const [feedBacks, setFeedBacks] = useState([])
    const key: any = process.env.REACT_APP_ABSTRACT_API_KEY;


    useEffect(() => {
        if (props?.courseTarificationForm !== undefined && props?.courseTarificationForm !== courseTarificationForm) {
            setCourseTarificationForm(props?.courseTarificationForm)
        }
    }, [props?.courseTarificationForm])

    const getTaridicationTraining = async (trainingId: number) => {
        const baseUrl3 = process.env.REACT_APP_BASE_URL11
        await axiosInstance.get(baseUrl3 + `offers/offerId/` + trainingId).then(async res => {
            await props?.getOfferPricingCourse(res.data)
        }).catch(err => console.error(err));
    }

    const getSessionOfferFn = async () => {
        const offerPath = {
            id: null,
            idEntity: props?.connectedUser?.idSchool,
            label: props?.courseInfo?.title,
            status: "VALIDE",
            typePricing: "STATIC",

            rules: [
                {
                    id: null,
                    key: "id_session",
                    value: props?.courseInfo?.id,
                    valueType: "number",
                    code_product: "SSN"
                },
                {
                    id: null,
                    key: "nb_month_access",
                    value: "-1",
                    valueType: "number",
                    code_product: "SSN"
                }
            ],
            paymentEngine: "STRIPE",
            pricings: [{
                id: null,
                period: "ONESHOT",
                status: "ACTIVE",
                price: 0,
                currency: "EUR",
                startDate: new Date(),
                endDate: null
            }],
            stripeAccount: props?.paymentMethod?.accountId
        }

        const baseUrl3 = process.env.REACT_APP_BASE_URL11
        await axiosInstance.get(baseUrl3 + `offers/offerId/` + props?.courseInfo?.id?.toString()).then(async res => {

            if (res.data) {
                await props?.getOfferPricingCourse(res.data)
            }
        }).catch(err => console.error(err));

    }

    useEffect(() => {
        if (props?.courseInfo !== null && props?.courseInfo !== undefined && props?.courseInfo?.id === idSession) {
            setTimeout(() => {
                getSessionOfferFn()
            }, 1000)
        }

    }, [props?.courseInfo])

    useEffect(() => {
        if (ips !== '') {
            fetchData('POST', `${process.env.REACT_APP_BASE_URL2}visit/info`, { address: ips, idCourse: props?.courseInfo?.id })
        }
    }, [ips])


    useEffect(() => {
        if (props?.courseInfo === undefined || props?.courseInfo === null || (props?.courseInfo && props?.courseInfo?.id !== Number(id))) {
            let filter = {
                trainingType: "SESSION",
                trainingId: Number(id)
            }

            props?.getContent(filter);
            props?.getOfferCourse(Number(id));
            if (props?.courseInfo?.idRegistrationForm !== null && props?.courseInfo?.idRegistrationForm !== 0 && props?.courseInfo?.idRegistrationForm !== undefined) {
                props?.getRegistrationForm(props?.courseInfo?.idRegistrationForm);
            }
        } else {
            setCourseInfo(props?.courseInfo);
        }
        if (location.pathname.includes("preview")) {
            fetch("https://ipgeolocation.abstractapi.com/v1/?api_key=" + key).then(response => response.json())
                .then(dataInfo => setIps(dataInfo.ip_address));

        }


    }, []);
    const data = async (id: number) => {
        // await props?.clearFeedbacks();

        // await props?.getAllFeedbacks(Number(id), 0, 'refresh');
        let feedbacks: any = await fetchData("POST", process.env.REACT_APP_BASE_URL8 + "feedback/all/" + id, {}).then(res => {
            return res
        })
            .catch(err => { return { feedbacks: [] } })



        let listPersonId: any = feedbacks?.feedbacks.filter((filter: any) => filter.userStatus === 'USER').map((obj: any) => { return obj.idPerson })


        const listPerson = await fetchData('POST', process.env.REACT_APP_BASE_URL2 + "person/student", listPersonId).then(response => { return response })

        feedbacks['feedbacks'] = feedbacks.feedbacks.map((obj: any) => {
            let idPer = obj.idPerson;
            const person = listPerson.find((personItem: any) => personItem.id === Number(idPer));

            if (person) {

                obj.idPerson = person;
            }
            return obj;
        })
        setFeedBacks(feedbacks)
    }

    useEffect(() => {
        if (id !== undefined && typeof id === 'number') {
            data(id);
        }
    }, [id])

    useEffect(() => {

        if (props?.connectedUser?.role?.includes("role_student")) {
            fetchData("GET", `${process.env.REACT_APP_BASE_URL3}follow/history/${id}/${props?.connectedUser?.idPerson}/${courseInfo?.type}`, {}).then((response: any) => {
                response.data ? setShow(true) : setShow(false)
            }).catch(err => { setShow(true) })

        }

    }, []);

    useEffect(() => {
        if (props?.courseInfo) {
            props?.courseInfo?.orders?.sort((a: any, b: any) => {
                if (a.index > b.index) {
                    return 1;
                } else if (a.index < b.index) {
                    return -1
                } else {
                    return 0
                }
            })
            setCourseInfo(props?.courseInfo);
        }
    }, [props?.courseInfo])
    useEffect(() => {
        if (props?.trainingOfferFromSession !== undefined) {
            setPrice(props?.trainingOfferFromSession[0]?.price);
        }

    }, [props?.trainingOfferFromSession])


    const convertTime = (minutes: number) => {
        if (minutes < 60) {
            return minutes + ' MN';
        } else {

            return Math.floor(minutes / 60) + 'H' + (((minutes % 60) < 10 ? '0' : '') + (minutes % 60));
        }
    }
    const avisClicked = () => {
        document.getElementById('clickLink')?.click();
    }

    useEffect(() => {
        if (props?.courseInfo != undefined) {
            const levelFouned = levels.find(obj => obj.value === props?.courseInfo?.level);
            setTrainingLevel((levelFouned) ? levelFouned?.label : '...');
        }

        if (
            props &&
            props?.courseInfo &&
            props?.courseInfo?.prerequisite !== undefined &&
            props?.courseInfo?.prerequisite !== null &&
            props?.courseInfo?.skills !== undefined &&
            props?.courseInfo?.skills !== null &&
            props?.courseInfo?.skills?.length > 0 &&
            props?.courseInfo?.objectives !== undefined &&
            props?.courseInfo?.objectives !== null
        ) {
            setContentWidth("100%");
        } else {
            setContentWidth("70%");
        }
    }, [props?.courseInfo])

    return (
        <div className={`${location.pathname.includes('/catalog/preview') ? 'mt-5' : ''}`}>
            <a href="#listFeedbacks" id="clickLink" style={{ display: 'none' }}></a>
            <DescPreviewCourse
                avisClicked={avisClicked}
                courseForm={props.sessionInfoFromProf}
                trainingOffer={props.trainingOfferFromSession}
                owner={courseInfo?.professor.firstName + " " + courseInfo?.professor.lastName}
                price={price}
                type={courseInfo?.type}
                time={convertTime(courseInfo?.duration)}
                resume={courseInfo?.resume}
                objectives={courseInfo?.objectives}
                prerequisite={courseInfo?.prerequisite}
                trainingLevel={trainingLevel}
                title={courseInfo?.title}
                media={courseInfo?.linkImg}
                video={courseInfo?.video}
            />

            {(props?.courseInfo?.skills !== 0) && <SkillsSectionComponent
                skills={courseInfo?.skills}
                objectives={props?.courseInfo?.objectives}
                prerequisite={props?.courseInfo?.prerequisite}
            />}
            <ContentPathSectionComponent exam={courseInfo?.exams[0]} orders={courseInfo?.subjectsBySession} time={convertTime(courseInfo?.duration)} title={courseInfo?.title} courseInfo={courseInfo} width={contentWidth} />
            <SchoolProfSectionComponent profData={courseInfo?.professor} photoProf={courseInfo?.professor.photo} />
            <HowToWorkSectionComponent courseForm={courseInfo} price={price} courseInfo={courseInfo} show={show} />
            <div id="listFeedbacks">
                <ListFeedbackSectionComponent feedBacks={feedBacks} />
            </div>
            <div className='suivre' style={{ textAlign: 'center', paddingBottom: "25px", paddingTop: "25px", backgroundColor: '#fff' }}>
                <button
                    className="btn-Primary large"
                    disabled
                > suivre ce {courseInfo?.type === "COURSE" ? "COURS" : courseInfo?.type}</button>
            </div>
        </div>
    )
}
const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    priceCourse: state.courseReducer.priceCourse,
    courseInfo: state.courseReducer.training,
    show: state.courseReducer.show,
    history: ownProps?.history,
    trainingOfferFromSession: ownProps.trainingOfferFromSession,
    courseTarificationForm: state.courseReducer.courseTarificationForm

});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({
    getOfferCourse,
    getContent,
    getRegistrationForm,
    createOrUpdateOfferCourse,
    getOfferPricingCourse
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PreviewSessionPage);