import { ComponentProps, FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { AppState } from "../../global-state/store/root-reducers";
import { getStudent } from "../../global-state/actions/profile-actions";
import { getCoursesForDirector } from "../../global-state/actions/course-actions";
import "../../translations/i18n";
import HeaderStudentsListComponent from "../../components/headers-page/header-students-list-component";
import TableStudentsListComponent from "../../components/tables/table-students-list-component";
import FilterStudentGroupsComponent from "../../components/filters/filter-students-groups-component";
import GroupListComponent from "../../components/group/group-list-component";
import { getGroupBySchool, searchGroupByLabel } from "../../services/groupe.student.service";
import { clearModal } from "../../global-state/actions/modal-actions";
import InviteStudentModalComponent from "../../components/modals/modal-forms/invite-student-modal-component";
import InvitationStudentListModalComponent from "../../components/modals/modal-forms/invitation-student-list-modal-component";
import Dialog from "@material-ui/core/Dialog";
import ValideInvitationModalComponent from "../../components/modals/modal-forms/valide-invitation-modal-component";
import AnnuleInvitationModalComponent from "../../components/modals/modal-forms/annule-invitation-modal-component";
import ErrorInvitationModalComponent from "../../components/modals/modal-forms/error-invitation-modal-component";
import { fetchCountTabsByFilter, fetchStudentListByFilter, getTrainingsBreiveDetailsApi } from "../../services/student.service";
import FilterStudentPageListComponent from "../../components/filters/filter-student-page-list-component";
import { StudentPageFilter } from "../../data-structures/interfaces/school.interfaces";
import AllStudentsTabsTableComponent from "../../components/tables/table-all-student/all-students-tabs-table-component";
import TableStudentsListLoadingComponent from "../../components/tables/table-students-list-loading-component";

const StudentsListPage: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props: any) => {

  //const [isSearch, setIsSearch] = useState(false);
  const [studentsList, setStudentsList] = useState<any>();
  const [loader, setLoader] = useState<boolean>(false);
  const [general, setGeneral] = useState<any>();
  const [followCount, setFollowCount] = useState<any>();
  const [groupList, setGroupList] = useState([]);
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [actualTabModal, setActualTabModal] = useState("one");
  const [modalListInviteOpened, setModalListInviteOpened] = useState<boolean>(false);
  const [inviteStudentsList, setInviteStudentsList] = useState([]);
  const [modalSuccessOpened, setModalSuccessOpened] = useState<boolean>(false);
  const [modalErrorOpened, setModalErrorOpened] = useState<boolean>(false);
  const [modalCancelOpened, setModalCancelOpened] = useState<boolean>(false);

  const [displayList, setDisplayList] = useState<any[]>([]);
  const [fetchFilter, setFetchFilter] = useState<StudentPageFilter>({
    idSchool: props?.connectedUser?.idSchool,
    page: 0,
    search: "",
    status: "All",
    courseIdsList: [],
    idPerson: props?.connectedUser?.idPerson
  });
  const [tabItemsNumber, setTabItemsNumber] = useState<number>(0);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [getNextPage, setGetNextPage] = useState<boolean>(true);
  const [endpointFetching, setEndpointFetching] = useState<boolean>(false)



  useEffect(() => {
    fetchStudentsData(fetchFilter.status, fetchFilter)
    setTabItemsNumber(fetchTabLimit(fetchFilter.status))
  }, [fetchFilter])


  const fetchStudentsData = async (activeTab: string, filter: any) => {
    setEndpointFetching(true)
    if (filter.status !== fetchFilter.status) {
      setLoadingTable(true)
    }
    let data: any[] = await fetchStudentListByFilter(filter, activeTab);
    if (data !== displayList) {
      if (filter.page === 0) {
        setDisplayList(data);
      } else {
        setDisplayList([...displayList, ...data])
      }
    }
    setLoadingTable(false)
    if (filter !== fetchFilter) {
      setFetchFilter(filter)
    }

    if (data?.length === 20) {
      setGetNextPage(true)
    } else {
      setGetNextPage(false)
    }
    setEndpointFetching(false)
  }

  useEffect(() => {
    if (fetchFilter?.idSchool !== props?.connectedUser?.idSchool) {
      setFetchFilter({
        idSchool: props?.connectedUser?.idSchool,
        page: fetchFilter.page,
        search: fetchFilter.search,
        status: fetchFilter.status,
        courseIdsList: fetchFilter.courseIdsList,
        idPerson: fetchFilter.idPerson
      })
    }
  }, [props.connectedUser])

  const updateFilter = async (key: string, value: any) => {
    switch (key) {
      case "search":
        setFetchFilter({
          idSchool: fetchFilter.idSchool,
          page: 0,
          search: value.toLowerCase(),
          status: fetchFilter.status,
          courseIdsList: fetchFilter.courseIdsList,
          idPerson: fetchFilter.idPerson
        })
        break;
      case "page":
        setFetchFilter({
          idSchool: fetchFilter.idSchool,
          page: Number(value),
          search: fetchFilter.search,
          status: fetchFilter.status,
          courseIdsList: fetchFilter.courseIdsList,
          idPerson: fetchFilter.idPerson
        })
        break;
      case "idSchool":
        setFetchFilter({
          idSchool: value,
          page: fetchFilter.page,
          search: fetchFilter.search,
          status: fetchFilter.status,
          courseIdsList: fetchFilter.courseIdsList,
          idPerson: fetchFilter.idPerson
        })
        break;
      case "status":
        setLoadingTable(true)
        setFetchFilter({
          idSchool: fetchFilter?.idSchool,
          page: 0,
          search: fetchFilter.search,
          status: value,
          courseIdsList: fetchFilter.courseIdsList,
          idPerson: fetchFilter.idPerson
        })
        break;

      default:
        break;
    }
  }

  const initPage = async () => {
    let coursesIdsList = await getTrainingsBreiveDetailsApi({
      "entityId": fetchFilter.idSchool,
      "entityType": "SCHOOL",
      "role": props?.connectedUser?.role,
      "idprof": props?.connectedUser?.role === 'role_prof' ? fetchFilter.idPerson : null
    })
    let filter: StudentPageFilter = {
      idSchool: props?.connectedUser?.idSchool,
      page: fetchFilter.page,
      search: fetchFilter.search,
      status: fetchFilter.status,
      courseIdsList: coursesIdsList,
      idPerson: fetchFilter.idPerson
    }
    setFetchFilter(filter)
    let tabsData = await fetchCountTabsByFilter(filter)
    setFollowCount(tabsData)
    let allStudentList: any = await fetchStudentsData(fetchFilter.status, filter);
    setDisplayList(allStudentList)
  }

  const fetchTabLimit = (tab: string) => {
    switch (tab) {
      case "All":
        return followCount?.GENERAL;
      case "Inscribed":
        return followCount?.INSCRIBED;
      case "Candidate":
        return followCount?.CANDIDATE;
      case "Unpaid":
        return followCount?.UNPAID;
      case "Certified":
        return followCount?.CERTIFIED;
      case "Suspended":
        return followCount?.SUSPENDED;
      default:
        console.error("actual tab", tab);
        return 0;
    }
  }


  useEffect(() => {
    if (props?.modalAction?.length !== 0 && props?.modalAction === "reload") {
      props.clearModal();
    }
  }, [props?.modalAction]);

  useEffect(() => {
    initPage();
    return () => {
      setFetchFilter({
        idSchool: props?.connectedUser?.idSchool,
        page: 0,
        search: "",
        status: "All",
        courseIdsList: [],
        idPerson: props?.connectedUser?.idPerson
      })
    };
  }, []);

  useEffect(() => {
    if (props?.connectedUser?.role === "role_director") {
      getGroupBySchool(props?.schoolInfo?.id).then((response: any) => {
        setGroupList(response);
      });
    }
    props?.clearModal();
  }, [props?.modalPage]);

  const SearchGroup = (value: string) => {
    if (value?.length > 2) {
      searchGroupByLabel(value, props?.schoolInfo?.id).then((response: any) => {
        setGroupList(response);
      });
      //setIsSearch(true);
    }
    if (value?.length === 0) {
      getGroupBySchool(props?.schoolInfo?.id).then((response: any) => {
        setGroupList(response);
      });
      //setIsSearch(false);
    }
  };

  const filterGroup = (value: boolean) => {
    //setIsSearch(value);
  };

  const updateActifTab = async (value: string) => {
    await updateFilter("status", value)
  }


  return (
    <div className='container-fluid'>
      <span
        id='step-student-page_director'
        style={{ position: "absolute" }}
      ></span>

      {modalOpened && (
        <div style={{ position: "absolute" }}>
          <Dialog
            style={{ width: "100%" }}
            maxWidth={"md"}
            open={modalOpened}
            onClose={(event, reason) => {
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                setModalOpened(false);
              }
            }}
            scroll={"body"}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'
          >
            <InviteStudentModalComponent
              setInviteStudentsList={setInviteStudentsList}
              setModalListInviteOpened={setModalListInviteOpened}
              setModalOpened={setModalOpened}
              general={general}
              setGeneral={setGeneral}
              studentsList={studentsList}
              setStudentsList={setStudentsList}
              actualTabModal={actualTabModal}
              setActualTabModal={setActualTabModal}
            />
          </Dialog>
        </div>
      )}

      {modalListInviteOpened && (
        <div style={{ position: "absolute" }}>
          <Dialog
            style={{ width: "100%" }}
            maxWidth={"md"}
            open={modalListInviteOpened}
            onClose={(event, reason) => {
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                setModalListInviteOpened(false);
              }
            }}
            scroll={"body"}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'
          >
            <InvitationStudentListModalComponent
              inviteStudentsList={inviteStudentsList}
              setModalOpened={setModalOpened}
              setModalListInviteOpened={setModalListInviteOpened}
              setModalSuccessOpened={setModalSuccessOpened}
              setModalErrorOpened={setModalErrorOpened}
              setModalCancelOpened={setModalCancelOpened}
            />
          </Dialog>
        </div>
      )}

      {modalSuccessOpened && (
        <div style={{ position: "absolute" }}>
          <Dialog
            style={{ width: "100%" }}
            maxWidth={"md"}
            open={modalSuccessOpened}
            onClose={(event, reason) => {
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                setModalSuccessOpened(false);
              }
            }}
            scroll={"body"}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'
          >
            <ValideInvitationModalComponent
              inviteStudentsList={inviteStudentsList}
              setInviteStudentsList={setInviteStudentsList}
              setModalSuccessOpened={setModalSuccessOpened}
              general={general}
              setGeneral={setGeneral}
              studentsList={studentsList}
              setStudentsList={setStudentsList}
            />
          </Dialog>
        </div>
      )}

      {modalErrorOpened && (
        <div style={{ position: "absolute" }}>
          <Dialog
            style={{ width: "100%" }}
            maxWidth={"md"}
            open={modalErrorOpened}
            onClose={(event, reason) => {
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                setModalErrorOpened(false);
              }
            }}
            scroll={"body"}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'
          >
            <ErrorInvitationModalComponent
              setModalListInviteOpened={setModalListInviteOpened}
              setModalErrorOpened={setModalErrorOpened}
            />
          </Dialog>
        </div>
      )}

      {modalCancelOpened && (
        <div style={{ position: "absolute" }}>
          <Dialog
            style={{ width: "100%" }}
            maxWidth={"md"}
            open={modalCancelOpened}
            onClose={(event, reason) => {
              if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                setModalCancelOpened(false);
              }
            }}
            scroll={"body"}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'
          >
            <AnnuleInvitationModalComponent
              setModalCancelOpened={setModalCancelOpened}
              setModalListInviteOpened={setModalListInviteOpened}
              setModalOpened={setModalOpened}
            />
          </Dialog>
        </div>
      )}

      <div className='row justify-content-center'>
        <HeaderStudentsListComponent
          modalOpened={modalOpened}
          setModalOpened={setModalOpened}
          actualTab={fetchFilter.status}
          updateActifTab={updateActifTab}
          followCount={followCount}
          groupList={groupList}
          setActualTabModal={setActualTabModal}
        />
        {fetchFilter.status !== "Groups" ? (
          <>
            <FilterStudentPageListComponent
              actualTab={fetchFilter.status}
              fetchNextPage={fetchStudentsData}
              fetchFilter={fetchFilter}
              updateFilter={updateFilter}
            />
            {/* <TableStudentsListComponent
              filter={fetchFilter}
              followCount={followCount}
              studentsList={displayList}
              pageNumber={fetchFilter.page}
              loader={loader}
              actualTab={fetchFilter.status}
              updateActifTab={updateActifTab} 
              modalOpened={modalOpened}
              setModalOpened={setModalOpened}
              setActualTabModal={setActualTabModal}
            /> */}

            <AllStudentsTabsTableComponent
              filter={fetchFilter}
              displayList={displayList}
              updateFilter={updateFilter}
              fetchLimit={tabItemsNumber}
              loadingTable={loadingTable}
              getNextPage={getNextPage}
              endpointFetching={endpointFetching}
            />

          </>
        ) : (
          <>
            <div className='d-flex flex-column justify-content-center mx-auto mb-4 pb-2'>
              <FilterStudentGroupsComponent
                groupList={groupList}
                Searching={SearchGroup}
                filter={filterGroup}
              />
              <GroupListComponent isSearch={false} groupList={groupList} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState, ownProps: any) => ({
  connectedUser: state.authReducer.connectedUser,
  schoolInfo: state.schoolInfoReducer.schoolInfo,
  nameSchool: state.authReducer.connectedSchoolInfo?.code,
  history: ownProps?.history,
  modalPage: state.modalReducer.modalPage,
  modalAction: state.modalReducer.modalAction
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      getStudent,
      getGroupBySchool,
      searchGroupByLabel,
      clearModal,
      getCoursesForDirector
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StudentsListPage);