import { ComponentProps, FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { AppState } from '../../../global-state/store/root-reducers';
import { useTranslation } from 'react-i18next';
import { StudentPageFilter } from '../../../data-structures/interfaces/school.interfaces';
import TableAllStudentsWithAction from '../../../elements/table/table-all-students-with-action';
import { returnStartPathUrl } from '../../../helpers/domainCheck';
import { useHistory } from 'react-router';
import TableElement from '../../../elements/table/normal-table-element';
import { clearHistoryArray, redirect } from '../../../global-state/actions/breadcrumb-actions';
import { getStudentInfoById } from '../../../global-state/actions/course-actions';
import { getStudent } from '../../../global-state/actions/profile-actions';
import EmptyListElement from '../../../elements/empty-list/normal-empty-list-element';
import TableStudentsListLoadingComponent from '../table-students-list-loading-component';
import { iconCourse, iconPath, iconSession } from '../../../styles/icons/icons';
import AllStudentsTabsTableNoDataComponent from './all-students-tabs-table-no-data-component';


const AllStudentsTabsTableComponent: FC<ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & ComponentProps<any>> = (props) => {
    const { t } = useTranslation();
    const dateFormat = require('dateformat');
    const moment = require('moment');
    const history = useHistory();
    //const [loadingTable, setLoadingTable] = useState<boolean>(true)
    const [formattedData, setFormattedData] = useState<any>();
    const [atBottom, setAtBottom] = useState<boolean>(false);
    let fetchFilter = useMemo<StudentPageFilter>(() => {
        if (props.filter !== undefined) {
            return props.filter;
        } else {
            return {
                idSchool: props?.connectedUser?.idSchool,
                page: 0,
                search: "",
                status: "All",
                courseIdsList: [],
                idPerson: props?.connectedUser?.idPerson
            }
        }
    }, [props.filter]);

    let displayList = useMemo(() => {
        // if (props.displayList !== undefined ) {
        //     return props.displayList
        // } else {
        //     return [];
        // }
        return props.displayList
    }, [props.displayList]);

    let nextPage = useMemo<boolean>(() => props.getNextPage, [props.getNextPage])

    const getHeaders = (tab: string) => {
        switch (tab) {
            case "All":
                return [
                    { title: t('students_2'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
                    { title: t('inscribedTimeStudent'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
                    { title: t('connectedThereIs'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
                    { title: t('Statut'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto" },
                ]
            case "Inscribed":
                return [
                    { title: t('students_2'), sorted: true, classNames: "w-17", scope: "col", buttonClasses: "d-flex button-trie px-0" },
                    { title: t('TitleFormations'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
                    { title: t('inscribedTimeStudent'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
                    { title: t('connectedThereIs'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
                    { title: "Temps passé", sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
                    { title: t('progress'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
                ]
            case "Candidate":
                return [
                    { title: t('students_2'), sorted: true, classNames: "w-22", scope: "col", buttonClasses: "d-flex button-trie px-0" },
                    { title: t('demandTable'), sorted: true, classNames: "w-20", scope: "col", buttonClasses: "d-flex button-trie px-0 " },
                    { title: t('Dossier'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
                    { title: t('TitleFormations'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0" },
                    { title: t('statusFilter'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto me-3" },
                ]
            case "Unpaid":
                return [
                    { title: t('students_2'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
                    { title: t('courseTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
                    { title: t('typeTable'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
                    { title: t('Fileacceptedon'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto" },
                ]
            case "Certified":
                return [
                    { title: t('students_2'), sorted: true, classNames: "w-17", scope: "col", buttonClasses: "d-flex button-trie px-0" },
                    { title: t('TitleFormations'), sorted: true, classNames: "w-30", scope: "col", buttonClasses: "d-flex button-trie px-0" },
                    { title: t('inscribedTimeStudent'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
                    { title: t('certifiedTitle'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
                    { title: t('obtentionPeriod'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
                    { title: t('diplomeButton'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto me-4" },
                ]
            case "Suspended":
                return [
                    { title: t('students_2'), sorted: true, classNames: "w-35", scope: "col", buttonClasses: "d-flex button-trie px-0" },
                    { title: t('inscribedTimeStudent'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 mx-auto" },
                    { title: t('suspendedAt'), sorted: true, classNames: "", scope: "col", buttonClasses: "d-flex button-trie px-0 ms-auto" },
                ]
            default:
                console.error("actual tab", tab);
                return [];
        }

    }

    function formatDuration(minutes: number) {
        if (minutes < 60) {
            return `${minutes} minutes`;
        }

        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        if (remainingMinutes === 0) {
            return `${hours} h`;
        }

        return `${hours} h ${remainingMinutes} minutes`;
    }

    const studentType: any = {
        'COURSE': t('Cours'),
        'PATH': t('parcoursTitle'),
        'SESSION': t('sessionTitle')
    }
    const badgeHandler = (dateSent: any) => {
        if (dateSent < 3) {
            return 'secondary'
        } else if (dateSent >= 3 && dateSent < 6) {
            return 'warning'
        }
        else {
            return 'refuse'
        }
    }
    const getContent = (tab: String, dataToFormat: any) => {
        switch (tab) {
            case "All":
                return dataToFormat?.map((student: any) => {
                    return {
                        row: {
                            student: student,
                            actionName: "goToPath",
                            classNames: "table-white",
                            columns: [
                                { content: student?.fullName, classNames: "", actionName: '' },
                                { content: student?.inscriptionDate, classNames: "text-center", actionName: '' },
                                {
                                    content: student?.lastConnectionDate,
                                    classNames: `text-center`,
                                    actionName: ''
                                },
                                {
                                    //content: (student?.lastConnectionDate === null) ? <span style={{ color: "#F5B95F" }}>{t('Enattente')}</span> : ((student?.studentStatus === "ADMIS") ? <span className='green-800'>{t('Admis')}</span> : <span className='red-800'>{t('Suspendu')}</span>),
                                    content: (student?.studentStatus === "Admis") ? <span className='green-800'>{t('Admis')}</span> : (student?.studentStatus === "Enattente") ? <span style={{ color: "#F5B95F" }}>{t('Enattente')}</span> : <span className='red-800'>{t('Suspendu')}</span>,
                                    classNames: `text-end`,
                                    actionName: ''
                                }
                            ]
                        }
                    }
                });
            case "Inscribed":
                return dataToFormat?.map((student: any) => {
                    const date = new Date()
                    date.setDate(date.getDate() - student?.lastConnexion)
                    return {
                        row: {
                            student: student,
                            actionName: "goToPath",
                            classNames: "table-white",
                            columns: [
                                { content: student?.firstName + " " + student?.lastName, classNames: "", actionName: '' },
                                {
                                    content: <div className="d-flex align-items-center">
                                        {(student?.type === 'COURSE') ? iconCourse : (student?.type === 'PATH') ? iconPath : iconSession}
                                        <span className="ms-2">{student?.courseTitle}</span></div>, classNames: "", actionName: ''
                                },
                                { content: dateFormat(student?.inscriptionDate, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                                { content: moment(date)?.fromNow(), classNames: "text-center", actionName: 'text-center' },
                                {
                                    content: <div className="d-flex align-items-center">{formatDuration(student?.passedDuration)}</div>,
                                    classNames: "",
                                    actionName: ''
                                },
                                { content: (student?.progress === null) ? '0%' : student?.progress + '%', classNames: "text-end", actionName: '' },
                            ]
                        }
                    }
                });
            case "Candidate":
                return dataToFormat?.map((student: any) => {
                    const dateSent = Math.ceil((new Date().getTime() - new Date(student?.demandDate).getTime()) / (1000 * 3600 * 24));
                    return {
                        row: {
                            student: student,
                            actionName: "goToPath",
                            classNames: "table-white",
                            columns: [
                                { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                                {
                                    content: <span>{dateFormat(student?.demandDate, "dd/mm/yyyy ")} {(student?.status === 'CANDIDATE') ?
                                        <span className={`badge-text ${(dateSent < 3) ? 'secondary' : (dateSent >= 3 && dateSent < 6) ? 'warning' : 'refuse'}`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span>
                                        :
                                        <span className={`badge-text secondary`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span>
                                    }
                                    </span>,
                                    classNames: "",
                                    actionName: ''
                                },
                                {
                                    content: (student?.registrationForm && student?.registrationForm !== null && student?.registrationForm !== 0) ? <button style={{ position: 'absolute', top: '40%' }} className={`btn-Document ${(student?.status === 'CANDIDATE') ? '' : 'refuse'}`}>
                                        <span className="material-icons-outlined" style={{ color: '#272727' }}>description</span>
                                    </button> : <button className="btn btn-Document" disabled> <span className="material-icons-outlined">highlight_off</span> </button>,
                                    classNames: `text-center with-icon relative-position d-flex align-items-center justify-content-center`,
                                    actionName: (student?.registrationForm && student?.registrationForm !== null && student?.registrationForm !== 0) ? 'goToRegistration' : ''
                                },
                                {
                                    content: <div className="d-flex align-items-center">
                                        {(student?.type === 'COURSE') ? iconCourse : (student?.type === 'PATH') ? iconPath : iconSession}
                                        <span className="ms-2">{student?.courseTitle}</span></div>,
                                    classNames: "",
                                    actionName: ''
                                },
                                {
                                    content: (student.status === "CANDIDATE") ? t("Candidat") : t('Refused'),
                                    classNames: `text-end badge-text  ${(student?.status === "CANDIDATE") ? 'primary' : 'danger'}`,
                                    actionName: ''
                                },
                            ]
                        }
                    }
                });
            case "Unpaid":
                return dataToFormat?.map((student: any) => {
                    const dateSent = Math.ceil((new Date().getTime() - new Date(student?.dateupdate).getTime()) / (1000 * 3600 * 24));
                    return {
                        row: {
                            student: student,
                            actionName: "goToPath",
                            classNames: "table-white",
                            columns: [
                                { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                                { content: student?.courseTitle, classNames: "text-center", actionName: '' },
                                { content: studentType[student.type], classNames: "text-center", actionName: '' },
                                {
                                    content: <span>{dateFormat(student?.dateupdate, "dd/mm/yyyy ")} {(student?.status === 'UNPAID') ?
                                        <span className={`badge-text ${badgeHandler(dateSent)}`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span>
                                        :
                                        <span className={`badge-text secondary`}>/ {t('TimePassed')} {dateSent} {t('joursVisit')}</span>
                                    }
                                    </span>,
                                    classNames: `text-end`,
                                    actionName: ''
                                }
                            ]
                        }
                    }
                });
            case "Certified":
                return dataToFormat?.map((student: any) => {
                    return {
                        row: {
                            student: student,
                            actionName: "goToPath",
                            classNames: "table-white",
                            columns: [
                                { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                                {
                                    content: <div className="d-flex align-items-center">
                                        {(student?.type === 'COURSE') ? iconCourse : (student?.type === 'PATH') ? iconPath : iconSession}
                                        <span className="ms-2">{student?.courseTitle}</span></div>, classNames: "", actionName: ''
                                },
                                { content: dateFormat(student?.inscriptionDate, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                                { content: dateFormat(student?.certificationDate, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                                { content: t('In') + " " + Math.ceil((new Date(student?.certificationDate).getTime() - new Date(student?.inscriptionDate).getTime()) / (1000 * 3600 * 24)) + " " + t('joursVisit'), classNames: "text-center", actionName: '' },
                                {
                                    content: <button style={{ position: "absolute", bottom: 20, right: 16 }} type="button" className="btn-Secondary medium icon-left">
                                        <span className="material-icons-outlined"> download </span>
                                        <div className="m-auto ps-2">{t('diplomeButton')}</div></button>, classNames: "text-end with-icon p-0 m-0 relative-position", actionName: 'downloadCertification'
                                },
                            ]
                        }
                    }
                });
            case "Suspended":
                return dataToFormat?.map((student: any) => {
                    return {
                        row: {
                            student: student,
                            actionName: "goToPath",
                            classNames: "table-white",
                            columns: [
                                { content: student?.firstName + " " + student.lastName, classNames: "", actionName: '' },
                                { content: dateFormat(student?.inscription, "dd/mm/yyyy"), classNames: "text-center", actionName: '' },
                                {
                                    content: dateFormat(student?.relation.endDate, "dd/mm/yyyy"),
                                    classNames: `text-end`,
                                    actionName: ''
                                }
                            ]
                        }
                    }
                });
            default:
                console.error("actual tab", tab);
                return [];
        }
    }

    const formatdTableData = async () => {
        let dataHeaders = getHeaders(fetchFilter.status);
        let tableContent = getContent(fetchFilter.status, displayList)
        setFormattedData({ headers: dataHeaders, body: tableContent })
        //setLoadingTable(false)
    }
    const doAction = async (value: any) => {
        if (value.action === 'goToPath' && props?.connectedUser?.role === 'role_prof') {
            await props?.getStudentInfoById(value.row.student.studentId, props?.schoolInfo?.id);
            await props?.getStudent(value.row.student);
            props?.clearHistoryArray();
            props?.redirect(t('students_2'), `${returnStartPathUrl()}/students/all`);
            props?.redirect(value.row.student?.fullName, `${returnStartPathUrl()}/students/profile/student/${value.row.student.studentId}`);
            history.push(`${returnStartPathUrl()}/students/profile/student/${value.row.student.studentId}`)
        } else if (value.action === 'goToPath' && props?.connectedUser?.role === 'role_director') {
            await props?.getStudentInfoById(value.row.student.studentId, props?.schoolInfo?.id);
            await props?.getStudent(value.row.student);
            props?.clearHistoryArray();
            props?.redirect(t('students_2'), `${returnStartPathUrl()}/students/all`)
            props?.redirect(value.row.student?.fullName, `${returnStartPathUrl()}/students/profile/student/${value.row.student.studentId}`)
            history.push(`${returnStartPathUrl()}/students/profile/student/${value.row.student.studentId}`)
        }
    }

    const handleScroll = async () => {
        if (
            !(atBottom)
            && ((window.innerHeight + window.scrollY + 1) >= document.documentElement.scrollHeight)
            && nextPage === true
        ) {
            setAtBottom(true);
        } else {
            setAtBottom(false);
        }
    }

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    })
    useEffect(() => {
        if (displayList !== undefined && formattedData?.list?.length !== 0) {
            formatdTableData();
        }
    }, [displayList])

    useEffect(() => {
        if (atBottom && props?.endpointFetching===false) {
            fetchNextPage()
        } else {
            setAtBottom(false)
        }
    }, [atBottom,props?.endpointFetching])

    const fetchNextPage = async () => {
        await props.updateFilter("page", fetchFilter?.page + 1)
    }

    useEffect(() => {
        let lang = localStorage.getItem("language") || "fr"
        if (lang !== "en") {
            require('moment/locale/' + lang);
            moment.defineLocale(lang, { parentLocale: lang });
        } else {
            require('moment/locale/' + 'fr');
            moment.defineLocale('fr', { parentLocale: 'fr' });
        }
    }, []);

    return (
        <div>
            {(props?.loadingTable === true) ?
                <div>
                    <TableStudentsListLoadingComponent />
                </div> :
                <div>
                    {displayList?.length !== 0 ?
                        <div>
                            {(fetchFilter?.status === "All" && props?.connectedUser?.role === 'role_director') ?
                                <div>
                                    <TableAllStudentsWithAction data={formattedData} doAction={(value: any) => { doAction(value) }} />
                                </div>
                                : <div>
                                    <TableElement data={formattedData} doAction={(value: any) => { doAction(value) }} />
                                </div>}
                            {(atBottom)
                                && <div className="d-flex w-100 align-items-center justify-content-center">
                                    <div className="m-3">
                                        <svg className="spinner" viewBox="0 0 50 50">
                                            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                                        </svg>
                                    </div>
                                </div>}
                        </div> :
                        <div>
                            <AllStudentsTabsTableNoDataComponent
                                status={fetchFilter?.status}
                                role={props?.connectedUser?.role}
                                withSearch={fetchFilter.search.length === 0 ? false : true}
                            />
                        </div>
                    }
                </div>}
        </div>
    )
}


const mapStateToProps = (state: AppState, ownProps: any) => ({
    connectedUser: state.authReducer.connectedUser,
    schoolInfo: state.schoolInfoReducer.schoolInfo,
});
const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
    bindActionCreators(
        {
            getStudentInfoById,
            getStudent,
            clearHistoryArray,
            redirect
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AllStudentsTabsTableComponent);