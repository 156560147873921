import React from 'react'
import EmptyListElement from '../../../elements/empty-list/normal-empty-list-element';
import { useTranslation } from 'react-i18next';

function AllStudentsTabsTableNoDataComponent(props: any) {
  const { t } = useTranslation();


  return (
    <div>
      {props.withSearch === true ?
        <div>
          <div className="w-100 text-center mt-5 pt-5">
            <EmptyListElement lines={["Aucun étudiant trouvé", "Veuillez effectuer une nouvelle recherche."]} />
          </div>
        </div>
        : <div>
          {props?.status === "All" &&
            <div className="w-100 text-center mt-5 pt-5">
              <EmptyListElement lines={[t('noStudentInscribed'), t('noStudentInscribed_line_2')]} />
              <button type="button" className="btn-Primary large icon-left mx-auto" onClick={() => { props?.setModalOpened(true); props.setActualTabModal('one') }}>
                {props?.role === "role_director" || props?.role === "role_prof"} <div className="d-flex align-items-center"><span className="material-icons">add</span>{t('addStudent')}<div className="m-auto ps-2 "></div></div>
              </button>
            </div>}
          {props?.status === "Inscribed" &&
            <div className="w-100 text-center mt-5 pt-5">
              <EmptyListElement lines={[t('noStudentFound')]} />
            </div>}
          {props?.status === "Candidate" &&
            <div className="w-100 text-center mt-5 pt-5">
             <EmptyListElement lines={[t('noCandidateFound')]} />
            </div>}
          {props?.status === "Unpaid" &&
            <div className="w-100 text-center mt-12">
              <EmptyListElement lines={[t('Nounpaidstudent')]} />
            </div>}
          {props?.status === "Certified" &&
            <div className="w-100 text-center mt-5 pt-5">
              <EmptyListElement lines={[t('noCertifedFound')]} />
            </div>}
          {props?.status === "Suspended" &&
            <div className="w-100 text-center mt-5 pt-5">
              <EmptyListElement lines={[t('noSuspendedFound')]} />
            </div>}
        </div>}
    </div>
  )
}

export default AllStudentsTabsTableNoDataComponent