import { fetchData } from "../base/functions/Functions";
import { StudentPageFilter } from "../data-structures/interfaces/school.interfaces";
import { studentTrainingDetailsBody } from "../data-structures/interfaces/trainings.interfaces";
import axiosInstance from "../helpers/interceptors";


let personBaseUrl = process.env.REACT_APP_BASE_URL2;
let orchestratorBaseUrl = process.env.REACT_APP_BASE_URL14;

export const getNumberOfCourses = async (filter: any) => {

  let baseURL = process.env.REACT_APP_BASE_URL3;
  const data = await axiosInstance
    .post(baseURL + "follow/getNumberofTraining", filter)
    .then((response) => {
      return response.data;
    });
  return data;
};


export const getFollowedTrainings = async (payload: any): Promise<studentTrainingDetailsBody[]> => {
  try {
    let fetchedTrainings: studentTrainingDetailsBody[] = await fetchData('POST', `${process.env.REACT_APP_BASE_URL14}training/getStudentsFollowedTrainings`, payload)
    return fetchedTrainings;
  } catch (e) {
    console.error("error while fetching when getting list of followed trainings ", e);
    return []
  }
}

export const getStudentsPerSchoolId = async (id: any, page: number, size: number): Promise<any[]> => {
  try {
    let students: any[] = await fetchData('GET', `${process.env.REACT_APP_BASE_URL2}person/getStudentsPerSchoolId/${id}?page=${page}&size=${size}`, {})
    return students;
  } catch (e) {
    console.error("error while fetching when getting list of students per school id ", e);
    return []
  }
}

export const fetchStudentListByFilter = async (filter: any, type: string): Promise<any[]> => {
  switch (type) {
    case "All":
      return fetchStudensByFilter(filter);
    case "Inscribed":
      return fetchStudentsInTrainingByFilter(filter);
    case "Candidate":
      return fetchCandidateStudentsByFilter(filter);
    case "Unpaid":
      return fetchUnpaidStudentsByFilter(filter);
    case "Certified":
      return fetchCertifiedStudentsByFilter(filter);
    case "Suspended":
      return fetchSuspendedStudentsByFilter(filter);
    case "Groups":
      return fetchGroupsByFilter(filter);
    default:
      console.error("actual tab", type);
      return [];
  }
}


export const fetchStudensByFilter = async (filter: StudentPageFilter): Promise<any[]> => {
  try {
    let payload = {
      "schoolId": filter.idSchool,
      "search": filter.search,
      "page": filter.page,
      "studentsListStatus": filter.status ==="All"? "all":filter.status
    }
    let studentList: any = await fetchData("POST", `${personBaseUrl}person/getAllStudentsList`, payload);
    return studentList;
  } catch (error: any) {
    console.error("error occurred during fetching the list of school students ", error);
    return []
  }

}

const fetchStudentsInTrainingByFilter = async (filter: any): Promise<any[]> => {  
  try {
    let payload = {
      "coursesId": filter.courseIdsList,
      "search": filter.search,
      "idProf": filter.idPerson,
    }
    let inscribedList: any = await fetchData("POST", `${orchestratorBaseUrl}followUp/allFollowsByInscribed/${filter?.idSchool}/${20}/${filter.page}`, payload);
    return inscribedList.list;
  } catch (error: any) {
    console.error("error occurred during fetching the list of students in training ", error);
    return []
  }
}

const fetchCandidateStudentsByFilter = async (filter: any): Promise<any[]> => {
  try {
    let payload = {
      "coursesId": filter.courseIdsList,
      "search": filter.search,
      "idProf": filter.idPerson,
    }
    let candidateList: any = await fetchData("POST", `${orchestratorBaseUrl}followUp/allFollowsByCandidate/${filter.idSchool}/${20}`, payload);
    return candidateList.list;
  } catch (error: any) {
    console.error("error occurred during fetching the list of students in training ", error);
    return []
  }
}

const fetchUnpaidStudentsByFilter = async (filter: any): Promise<any[]> => {
  try {
    let payload = {
      "coursesId": filter.courseIdsList,
      "search": filter.search,
      "idProf": filter.idPerson,
    }
    let unpaidList: any = await fetchData("POST", `${orchestratorBaseUrl}followUp/allFollowsUnpaid/${filter.idSchool}/${20}`, payload);
    return unpaidList.list;
  } catch (error: any) {
    console.error("error occurred during fetching the list of unpaid students ", error);
    return []
  }
}

const fetchCertifiedStudentsByFilter = async (filter: any): Promise<any[]> => {
  try {
    let payload = {
      "coursesId": filter.courseIdsList,
      "search": filter.search,
      "idProf": filter.idPerson,
    }
    let certifiedList: any = await fetchData("POST", `${orchestratorBaseUrl}followUp/allFollowsByCertified/${filter.idSchool}/${20}`, payload);
    return certifiedList.list;
  } catch (error: any) {
    console.error("error occurred during fetching the list of certified students ", error);
    return []
  }
}

const fetchSuspendedStudentsByFilter = async (filter: any): Promise<any[]> => {
  try {
    let payload = {
      "coursesId": filter.courseIdsList,
      "search": filter.search,
      "idProf": filter.idPerson,
    }
    let certifiedList: any = await fetchData("POST", `${orchestratorBaseUrl}followUp/allFollowsBySuspended/${filter.idSchool}/${20}`, payload);
    return certifiedList.list;
  } catch (error: any) {
    console.error("error occurred during fetching the list of suspended students ", error);
    return []
  }
}

const fetchGroupsByFilter = async (filter: any): Promise<any[]> => {
  return [];
}


export const fetchCountTabsByFilter = async (filter: any): Promise<any> => {
  try {
    let payload = {
      "coursesId": filter.courseIdsList,
      "search": filter.search,
    }
    let kpiList: any = await fetchData("POST", `${orchestratorBaseUrl}followUp/allFollowsCount/${filter.idSchool}/20`, payload);
    return kpiList;
  } catch (error: any) {
    console.error("error occurred during fetching the list of suspended students ", error);
    return {
      "CERTIFIED": 0,
      "SUSPENDED": 0,
      "UNPAID": 0,
      "CANDIDATE": 0,
      "INSCRIBED": 0,
      "GENERAL": 0
    }
  }
}


export const getTrainingsBreiveDetailsApi = async (payload:any) => {

  const listeStudents: any = await fetchData("POST", `${process.env.REACT_APP_BASE_URL}training/getTrainingsBreveDetails`, payload)
    .then(async (resCourses: any) => {
      return resCourses?.map((course: any) => {
        return { id: course.id, title: course.title, type: course.type, idRegistration: course.idRegistrationForm }
      });
    })
    .catch(error => { return [] });
  return listeStudents
}


